// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
.application {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 60px;
    height: 60px;
    border-radius: 22.5%;
    overflow: hidden;
  }

  .app_name {
    margin-top: 6px;
    font-size: 13px;
    color: $text_color;
  }
}
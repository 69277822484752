// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
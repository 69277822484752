// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#action_sheet {
  padding: 10px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .2s;
  background-color: #fff;

  .back {
    position: relative;
    padding: 0 6px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    border-bottom: 1px solid rgba(200, 200, 200, .2);

    .back_icon {
      position: absolute;
      top: 10px;
      left: 6px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .action_sheet_main {
    padding: 10px 0;
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
.swipper_page {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .app_group {
    margin: 10px calc(12.5% - 30px);
    height: 120px;
    border-radius: 13.5px;
    background-color: $opac_bg;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: $other_text_color;
  }

  .app_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .app_item {
      display: flex;
      justify-content: center;
      width: 25%;
    }
  }
}
// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#swipper_app {
  position: relative;
  height: calc(100% - 140px);
  overflow: hidden;
  cursor: move;

  .swipper_pages {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .4s
  }

  .swipper_dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 8px;
    text-align: center;

    .dot {
      display: inline-block;
      margin: 0 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .4);

      &.active {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
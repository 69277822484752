// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#bottom_bar {
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
  height: 80px;
  border-radius: 20px;
  background-color: $opac_bg;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .app_item {
    width: 60px;
  }
}
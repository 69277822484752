// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#friend_link {
  .link_group {
    margin-bottom: 10px;

    .group_name {
      padding: 6px 2px;
      color: #606266;
      background-color: #f0f0f0;
    }

    .group_list {
      .link_item {
        height: 40px;
        line-height: 40px;
        color: #606266;
        font-size: 16px;
        border-bottom: 1px solid #909399;
      }
    }
  }
}
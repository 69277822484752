// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#status_bar {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  .time_bar {
    font-weight: 600;
  }

  .status_icons {
    display: flex;
    justify-content: space-around;
    width: 60px;

    .status_icon {
      font-size: 14px;
      width: 20px;
      height: 16px;
    }
  }
}

@media(max-width: 520px) {
  #status_bar {
    display: none;
  }
}
// 全局配置
$body_bgc: #f0f0f0;
$text_color: #fff;
$other_text_color: #303133;
$opac_bg: rgba(255, 255, 255, .6);
#mobile {
  position: relative;
  padding: 10px;
  width: 400px;
  height: 800px;
  border-radius: 20px;
  background-image: url('../../assets/images/mobile_bgm.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

@media(max-width: 520px) {
  #mobile {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}